import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Paper, Typography, MenuItem, CircularProgress } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
    const [successMsg, setSuccessMsg] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        gender: '',
        birthDate: null // Adjust this based on the date format expected by the API
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        let klubParam = searchParams.get('klub');
        let typeParam = searchParams.get('type');

        if (!klubParam || !typeParam) {
            setError('Klub and/or type parameters are missing.');
            setDisableSubmit(true);
        }
    }, [window.location.search]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const searchParams = new URLSearchParams(window.location.search);
        let klubParam = searchParams.get('klub').toUpperCase();
        let typeParam = searchParams.get('type');
        let klubId = 'group_55'; // Default to SC.
        if (klubParam === 'AFC') {
            klubId = 'group_61';
        } else if (klubParam === 'NFK') {
            klubId = 'group_60';
        }

        if (klubParam !== 'AFC' && klubParam !== 'NFK') {
            klubParam = 'SC';
        }

        if (typeParam !== 'elev' && typeParam !== 'tandem') {
            typeParam = 'tandem';
        }

        // Birthdate cannot be empty.
        if (!formData.birthDate) {
            setError('Birthdate is required.');
            setLoading(false);
            return;
        }

        const otherFormData = formData;
        const postData = {
            moduleId: 95,
            moduleItemTypeId: (typeParam === 'elev') ? 162 : 163, // 162 for elev, 163 for tandem
            fields: JSON.stringify({
                "856": otherFormData.name, 
                "857": otherFormData.email,
                "859": otherFormData.phoneNumber,
                "860": otherFormData.birthDate.format('YYYY-MM-DD'),
                "987": klubId,
                "890": otherFormData.gender,
                "863": new Date().toISOString()
            })
        };

        try {
            await axios.post('submit.php', postData);
            
            // Show success message briefly and then redirect
            setSuccessMsg(true);

            setTimeout(() => {
                window.location.href = 'https://klubadmin.dfu.dk/' + (typeParam == 'elev' ? 'Indmelding' : 'Tandem') + '/' + klubParam.toLowerCase();
            }, 3000);
        } catch (error) {
            // Handle error
            console.error('Error:', error);
            setLoading(false);
            setError('An error occurred. Please try again later.');
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
            <Grid item xs={11} md={6}>
                <Paper elevation={3} style={{ padding: 20 }}>
                    <Typography variant="h5" gutterBottom>
                        Tilmelding
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="name"
                                    label="Fulde navn"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="phoneNumber"
                                    label="Telefonnummer"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="gender"
                                    select
                                    label="Gender"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={formData.gender}
                                    onChange={handleChange}
                                    disabled={loading}
                                >
                                    <MenuItem value="Kvinde">Kvinde</MenuItem>
                                    <MenuItem value="Mand">Mand</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        name="birthDate"
                                        label="Fødselsdato"
                                        fullWidth
                                        required
                                        format="DD/MM/YYYY"
                                        value={formData.birthDate}
                                        onChange={(date) => setFormData({ ...formData, birthDate: date })}
                                        disabled={loading}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading || disableSubmit}>
                                    {loading ? <CircularProgress size={24} /> : 'Tilmeld'}
                                </Button>
                                <Snackbar
                                    autoHideDuration={6000}
                                    message="Tilmelding er gennemført. Du vil blive omdirigeret til indmeldingserklæring."
                                    open={successMsg}
                                    onClose={() => setSuccessMsg(false)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Ved at tilmelde dig accepterer du vores <a href="https://aversi.dk/skydive-copenhagen-betingelser/" target="_blank">betingelser</a>.
                                </Typography>
                            </Grid>
                            {error && (
                                <Grid item xs={12}>
                                    <Typography color="error" variant="body1">
                                        {error}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}
    
export default App;
